
import Vue from 'vue';
import { CurrentSection, LayoutSection } from '../../../../types/layout';
import analytics from '../../../../mixins/analytics';
import utils from '../../../../util';
import { C360AsideMenu } from '@c360/ui';
import util from '../../../../util';

const icons = utils.SIDEBAR_ICONS;
const includedSummarySection = ['googlevideo'];

export default Vue.extend({
  name: 'sidebar-c360',
  props: ['currentSection'],
  components: {
    C360AsideMenu,
  },
  mixins: [analytics],
  data: (): {
    rail: boolean;
    key: Number;
  } => ({
    rail: false,
    key: 1,
  }),
  computed: {
    highlightedItem(): string {
      if (this.currentSection.id === 'ordersummary') return 'orderlist';
      return this.currentSection.id;
    },
    isShowAllTactics(): boolean {
      return this.$store.state?.advertiser?.advertiserInfo?.data?.isShowAllTactics;
    },
    validSections(): Array<LayoutSection> {
      return this.getValidSections(this.$store.state.customer?.currentDashboard?.products);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentSections(): any {
      if (!this.validSections) return;
      return utils.groupTactics(this.validSections);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sidebarSummarySections(): any {
      const advertiserSummary = {
        id: 'summary',
        title: 'Product Summary',
      };
      const orderSummary = {
        id: 'orderlist',
        title: 'Order Summary',
      };

      return {
        id: 'summary',
        title: 'Summary',
        icon: 'Chart_Pie',
        subItems: util.hasRight(this.$store.state.customer.user, ['SHOW_ORDER_SUMMARY_VIEW'], true)
          ? [orderSummary, advertiserSummary]
          : null,
      };
    },
    formatTacticsForSidebar(): object[] {
      const excludeTactics = [
        'broadstreet',
        'fbinsights',
        'googlesearch',
        'linear',
        'trugeofence',
        'tv2linear',
        'tv2crossplatform',
        'tv2ott',
      ];

      if (!this.currentSections || !this.currentSections.length) return [];

      const formattedTactics = this.currentSections.map(tactic => ({
        id: tactic.id,
        title: tactic.id,
        icon: this.getIcon(tactic.id),
        subItems: tactic.tabArray
          .filter(tab => {
            if (this.isShowAllTactics) {
              return !excludeTactics.includes(tab?.id) || tab.HasCampaigns;
            } else {
              return tab.HasCampaigns;
            }
          })
          .filter(tab => tab.id !== 'tv2ott')
          .map(tab => ({
            id: tab.id,
            title: tab.friendlyName,
            disabled: !tab.HasCampaigns,
            HasCampaigns: tab.HasCampaigns,
            DisplayName: tab.DisplayName,
          })),
      }));

      const filteredTactics = formattedTactics.filter(tactic => tactic?.subItems?.length);
      filteredTactics.unshift(this.sidebarSummarySections);

      return filteredTactics;
    },
    showEnvBar(): boolean {
      return util.getEnvForProductLink(true) !== 'prod';
    },
    sidebarOffset(): number {
      if (utils.getEnvForProductLink(true) !== 'prod') {
        return 40;
      }
      return 0;
    },
    showMissingTacticMsg(): boolean | null {
      if (!this.validSections) return null;
      // count for all possible sections except summary
      let totalTacticCount = this.validSections.length;
      if (this.validSections.find(section => section.id === 'summary')) {
        totalTacticCount -= 1;
      }

      const totalValidTactics = this.currentSections.reduce((sum, section) => {
        sum += section.tabArray.length;
        return sum;
      }, 0);

      return totalValidTactics < totalTacticCount;
    },
  },
  watch: {
    rail() {
      if (this.rail === true)
        setTimeout(() => {
          this.key = this.key + 1;
        }, 150);
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click($event): any {
      this.setCurrentSection($event);
      if (['summary', 'orderlist', 'googlevideo'].includes($event.id)) {
        return;
      }
      this.$store.dispatch('clickedTacticFromSidebar', $event.id);
    },
    getIcon(id: string): string {
      const item = icons.find(icon => icon.id === id);
      if (item) return item.icon;
      return 'Summary';
    },
    setCurrentSection(section: CurrentSection): void {
      // This is a hack to make sure the summary section is always the first section
      section.hasSummary = includedSummarySection.includes(section?.id);
      this.useFriendlyMenuName = false;
      this.showMenu = false;
      this.$emit('set-current-section', section);
      // From now we are tracking the navigation to tactics after fetching first campaign in the tactic
      // googlevideo has summaryView by default
      if (['summary', 'orderlist', 'googlevideo'].includes(section.id)) {
        this.analyticTrack(this.trackValue.EVENT_NAV_TACTICS, section.id);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValidSections(sections: any): Array<LayoutSection> | null {
      // get a list of strings returned from an api with valid products for this advertisers
      const products = this.$store.state?.customer?.currentDashboard?.products;
      const validTactics: Array<string> = products?.map(product => product.id);
      // need to do this for global date picker
      // CampTypes changes when global date picker is changed
      const validCampTypes: Array<string> =
        this.$store.state?.customer?.campaigns?.CampTypes?.map(product => product.label.toLowerCase()) || [];

      // need to change "siteimpact" to "emailsi" and add "summary"
      const index = validCampTypes?.indexOf('siteimpact');
      if (index >= 0) {
        validCampTypes[index] = 'emailsi';
      }
      validCampTypes.push('summary');
      if (!validTactics) {
        // console.error('Missing valid tactics');
        return null;
      }

      if (!sections) {
        return [];
      }

      return products;
    },
  },
});
